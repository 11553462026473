/* PrivacyPolicy.css */
.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy-header {
    background-color: #001529;
    color: #fff;
    padding: 16px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    font-size: 24px;
  }
  
  .privacy-policy-content {
    padding: 24px;
  }
  
  .privacy-policy-content .ant-typography {
    color: #333;
  }
  
  .privacy-policy-content .ant-typography-title {
    margin-top: 24px;
    color: #001529;
  }
  
  .privacy-policy-content ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  