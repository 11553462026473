/* Profile.css */

/* Overall page styling */
/* .main {
  background-color: #f0f0f0;
  padding: 20px;
} */

.temples {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Heading style */
.login-txt {
  /* font-size: 28px; */
  color: #333;
  margin: 10px 0 20px 0;
}

/* Edit checkbox style */
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.checkbox-label {
  font-size: 16px;
  color: #666;
}

/* User form style */
.user-form {
  margin-top: 20px;
  /* background-color: #fcfafa;
  padding: 50px;
  border: 1px solid #e0e0e0; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.form-box {
  /* background-color: #f0f0f0; */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Full Name input field style */
.full-name-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
}

/* Profile image style */
.user-pic {
  text-align: center;
}

.profile-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

/* Gender and Language Select styles */
.select-label {
  font-size: 16px;
  color: #333;
  margin-top: 20px;
}

/* Save button style */
.save-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.save-button:hover {
  background-color: #0056b3;
}

/* ProfileLocation.css */

/* Container for the entire form */
.profile-forms {
  margin-top: 20px;
  /* background-color: #f0f0f0; */
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* City and State Select styles */
.select-label {
  font-size: 16px;
  color: #333;
  margin-top: 20px;
}

/* City Select style */
.city-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
}

/* State Select style */
.state-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
}

/* Location button style */
.loc-button {
  margin-top: 20px;
  text-align: center;
}

/* .profile-form-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .profile-form-button:hover {
    background-color: #0056b3;
  } */
