@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

body {
    font-family: "Roboto Condensed", sans-serif;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.temple-links,
.social-links {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.temple-links a,
.social-links a {
    margin: 0 15px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    font-family: "Roboto Condensed", sans-serif;
}

.social-links i {
    margin-right: 5px;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

h4.foothd {
    font-size: 16px;
    font-weight: 600;
    color: #faac23;
    padding-bottom: 5px;
    font-family: "Roboto Condensed", sans-serif;
}

.site-footer a {
    color: white !important;
 font-size:12px;
}

.social-icons li {
    display: inline-block;
    margin-bottom: 4px;
}

.fa-facebook-square:before {
    content: "\f082";
}

.site-footer .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 32px;
    margin-left: -2px;
    margin-right: 2;
    border-radius: 7px;
    background-color: white;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500px !important;
    font-family: "FontAwesome" !important;
}

.fbtn {
    margin-top: 19px;
    margin-left: -3px;
    margin-bottom: 16px;
}

.footbtn {
    padding: 10px 39px;
    background-color: #fab223;
    color: black !important;
    border-radius: 6px;
    font-size: 17px;
    font-weight: 600;
}

.site-footer {
    background: linear-gradient(45deg, #730260, #a73d08);
    padding: 32px 0 10px;
    font-size: 15px;
    line-height: 32px;
    color: white;
    width: 100%;
    font-family: "Roboto Condensed", sans-serif;
}

.site-footer {
    background-size: cover !important;
}

.social-icons {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.social-icons a {
    background-color: #eceeef;
    color: #c93937 !important;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}

.social-icons a.facebook:hover {
    background-color: #ff5600;
    color: white !important;
}

.social-icons a.twitter:hover,
.social-icons a.instagram:hover {
    background-color: #ff5600;
    color: white !important;
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
    color: #fff;
    background-color: #ff5600;
}

.social-icons a.linkedin:hover {
    background-color: #ff5600;
    color: white !important;
}

.fotterlogo {
    height: 200px;
}

@media only screen and (max-width: 992px) {
    .site-footer {
        display: none;
    }

    /* .temple-links,
    .social-links {
        flex-direction: column;
       
    }
    .temple-links a,
    .social-links a {
        margin: 10px 0;
        
        text-align: center;
       
    }
    .col-md-3,
    .col-md-2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .fotterlogo {
        height: 60px;
        
    }
    h4.foothd{
        font-size: 16px !important;     
    } */
}
