.button-sec a{
    color:black;
}

.onhover-text button{
    background-color: #391132;
    color: white;
    border: none;
}

.button-sec button:hover{
    color: black;
}

.select-element {
    color: black; /* Set the text color to black */
    /* Add any other styles you need */
  }