.ant-row.css-dev-only-do-not-override-ed5zg0 {
    justify-content: center;
}

.card-container {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    margin-left: 30px;
    justify-content: center;
}