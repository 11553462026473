/* AddPage1 CSS */
.add-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(180deg, #ebdef0, #f8d3b8);
  }
  
  .add-page-container .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-page-container .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .add-page-container .page-header h1 {
    margin: 0;
    font-size: 24px;
  }
  
  .add-page-container .form-box {
    padding: 20px;
    
  }
  
  .add-page-container .form-title {
    color: #f78b75;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .add-page-container .form-item {
    margin-bottom: 20px;
  }
  
  .add-page-container .ant-input {
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .add-page-container .ant-input:focus,
  .add-page-container .ant-input:hover {
    border-color: #f78b75;
  }
  
  .add-page-container .ant-input-textarea {
    min-height: 120px;
    resize: vertical;
  }
  
  .add-page-container .ant-btn {
    font-size: 16px;
    border-radius: 4px;
    /* padding: 10px 20px; */
  }
  
  .add-page-container .ant-btn-primary {
    background-color: #ffc107;
    border-color: #f78b75;
    color: #51093b;
  }
  
  .add-page-container .ant-btn-primary:hover,
  .add-page-container .ant-btn-primary:focus {
    background-color: #f68a74;
    border-color: #f68a74;
  }
  
  .add-page-container .ant-typography a {
    color: #f78b75;
  }
  
  .add-page-container .ant-typography a:hover {
    text-decoration: underline;
  }
  
  .add-page-container .message-success,
  .add-page-container .message-error {
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .add-page-container .message-success {
    background-color: #dff0d8;
    color: #3c763d;
    border: 1px solid #d6e9c6;
  }
  
  .add-page-container .message-error {
    background-color: #f2dede;
    color: #a94442;
    border: 1px solid #ebccd1;
  }
  