ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-dark.css-dev-only-do-not-override-ed5zg0 {
  display: flex;
  justify-content: center;
  height: 50px;
}

/* Header styles */
.header {
  background-image: linear-gradient(45deg, #ff6100, #d62b86);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  /* Set a high z-index to ensure the header stays on top of other content */
  border-bottom: 3px solid rgb(204, 191, 10);
}

/* Logo styles */
.logo img {
  width: 50px;
  height: 50px;
  margin-right: 50px;
  cursor: pointer;
}

.nav-links {
  display: flex;
}

/* Navigation links styles */
.nav-links a {
  display: flex;
  color: #fff;
  text-decoration: none;
  margin-right: 40px;
  font-weight: 500;
}

.nav-links a:last-child {
  margin-right: 0;
  /* Remove margin from the last link */
}

.nav-links a:hover {
  color: #646262;
  text-decoration: none;
}

.search1 {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.not {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not h1 {
  color: rgb(255, 136, 0);
  margin-bottom: 20px;
  text-align: center;
}

.not.container {
  min-height: 250px;
  margin-top: 50px;
  /* Set an appropriate height to ensure smooth scrolling */
}

.notification-button {
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.carousel-container {
  width: 100%;
  max-width: 600px;
}

.carousel-container1 {
  width: 100%;
  max-width: 600px;
  margin-left: 30%;

}

.notification-list {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.notification-read {
  background-color: #f0f0f0;
}

.notification-unread {
  background-color: #fff;
}

.notification-list h6 {
  font-size: 18px;
  margin-bottom: 5px;
}

.notification-list p {
  font-size: 14px;
  margin-bottom: 0;
}

.notification-list .anticon-delete {
  color: red;
  cursor: pointer;
  font-size: 20px;
  float: right;
}

/* Add these styles to your existing CSS file or create a new one */
.video-container {
  position: relative;
  text-align: center;
}

.welcome-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
  /* Set the color you want for the text */
}

.welcome-text h1 {
  color: white;
}

.down-arrow {
  margin-top: 10px;
  /* Add some margin below the welcome text */
  cursor: pointer;
}

.image img {
  width: 100px;
  height: 100px;
}

.image {
  display: flex;
  justify-content: center;
}

.down-arrow-container {
  position: relative;
  display: flex;
  justify-content: center;
}

/* .circle {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgb(240, 237, 237);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 40% !important;
} */

/* .circle:hover {
  background-color: #140430; 
  
} */

.container1 {
  text-align: center;
  min-height: 200px;
}

.center-text {
  text-align: center;

}

.center-text1 {
  text-align: center;
  min-height: 600px;
}

.centered-paragraphs {
  display: inline-block;

  max-width: 1200px;
}

.paragraph-style {
  font-family: "Times New Roman", serif;
  /* Change the font family to Arial */
  font-size: 16px;
  /* Change the font size to 16px */
  line-height: 1.6;
  /* Adjust the line-height as needed */
  /* Add any other font-related styles you want */
}

.image-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  /* border-bottom: 3px solid rgb(204, 10, 10); */
  /* min-height: 300px; */
  /* flex-wrap: wrap; */
}

.circle-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.circle-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.copyright {
  text-align: center;
  background: #ededed;
  padding: 5px 0;
  font-weight: 500;
  border-top: 1px solid #e1e1e1;
  margin-top: 40px;
}

.circle-image:hover {
  transform: scale(1.1);
  z-index: 1;
  /* To bring the expanded circle above other circles during hover */
  /* Add any other styles you want for the hover effect here */
}

.circle-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  flex: 1 1 calc(33.33% - 16px);
  /* Ensure 3 items in a row */
  max-width: calc(33.33% - 16px);
  /* Prevent items from growing */
  box-sizing: border-box;
}

.daily {
  text-align: center;
  margin-top: 10px;
}

.daily h1 {
  font-size: 20px;
  margin: 5px 0;
}

.expanded {
  transform: scale(1.1);
}



@media (max-width: 768px) {

  /* Mobile view breakpoint */
  .circle-info-container {
    flex: 1 1 calc(33.33% - 8px);
    /* Ensure 3 items per row on mobile */
    max-width: calc(33.33% - 8px);
    /* Adjust max-width for spacing */
  }
}

.circle-image img {
  width: 100%;
  border-radius: 50%;
  /* Make images circular */
}

.daily {
  text-align: center;
  margin-top: 8px;
}